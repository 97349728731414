<template>
    <div class="selectLabel scrolling">
        <van-search @search="onSearch" v-model="queryParam.name" placeholder="请输入标签名称" />
        <div>
            <div class="item" v-for="(tag, tagIndex) in tags" :key="tag.id">
                <van-cell :title="tag.name" @click="handleClick(tag)" is-link>
                    <template #right-icon>
                        <van-icon :name="tag.unfold ? 'arrow-down' : 'arrow'" />
                    </template>
                </van-cell>
                <div class="persons" v-if="tag.unfold">
                    <div style="padding-left: 16px" v-if="tag.persons === 0">加载中...</div>
                    <div class="tagContainer" v-if="tag.persons && tag.persons.length > 0">
                        <van-checkbox-group v-model="tag.selectPerson">
                            <van-cell-group>
                                <van-cell
                                    v-for="(person, index) in tag.persons"
                                    clickable
                                    :key="person.id"
                                    @click="toggle(index, tagIndex)"
                                >
                                    <template #title>
                                        <OpenData
                                            :type="getDataType(person)"
                                            :openid="person.deptId || person.userId"
                                            :corpid="person.corpId"
                                        ></OpenData>
                                    </template>
                                    <template #right-icon>
                                        <van-checkbox :name="person" :ref="`checkboxes-${tagIndex}-${index}`" />
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-checkbox-group>
                        <!-- <a-tag
              class="tags flex0"
              color="blue"
              closable
              @close="delPerson(tag, person.id)"
              :key="person.id"
              v-for="person in tag.persons">
              <OpenData :type="getDataType(person)" :openid="person.userId || person.deptId" :corpid="person.corpId"></OpenData>
            </a-tag> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <a-button @click="handleSubmit" type="primary" block> 确定添加 </a-button>
        </div>
    </div>
</template>
<script>
import { delLablePerson, documentLablePage, getLablePersonList } from '@/api/modular/fileStream/documentLabelManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { initWx } from '../components/minxin'
export default {
    mixins: [initWx],
    components: {
        OpenData,
    },
    data() {
        return {
            queryParam: {},
            tags: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        onSearch() {
            this.getTags()
        },
        toggle(index, tagIndex) {
            this.$refs[`checkboxes-${tagIndex}-${index}`][0].toggle()
        },
        addLabelName() {
            this.$router.push({ name: 'addLabelName' })
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        getDataKeyAndValue(node) {
            const key = node.deptId ? 'id' : 'userId'
            return {
                [key]: node.deptId || node.userId,
            }
        },
        handleSubmit() {
            let array = []
            this.tags.forEach((j) => {
                if (Array.isArray(j.selectPerson)) array = array.concat([...j.selectPerson])
            })
            const result = [...new Set(array)].map((j) => ({
                corpId: j.corpId,
                ...this.getDataKeyAndValue(j),
                type: j.deptId ? 'departmentName' : 'userName',
            }))
            console.log(result)
            this.$emit('selectPersonsFromLabel', result)
        },
        handleClick(tag) {
            tag.unfold = !tag.unfold
            if (tag.persons === 0) {
                getLablePersonList({
                    labelId: tag.id,
                })
                    .then((res) => {
                        if (res.code === 200) {
                            tag.persons = res.data
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        tag.persons = []
                    })
            }
        },
        toAddPerson(tag) {
            this.$router.push({
                name: 'addLabelPerson',
                query: {
                    tag: encodeURIComponent(JSON.stringify(tag)),
                },
            })
        },
        delPerson(tag, id) {
            delLablePerson([
                {
                    id,
                },
            ])
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        const tagIndex = tag.persons.findIndex((j) => j.id === tag.id)
                        tag.persons.splice(tagIndex, 1)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows.map((j) => ({ ...j, unfold: false, persons: 0, selectPerson: [] }))
                    console.log(this.tags, 'this.tags')
                } else {
                    res.message && this.$message.warning(res.message)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.selectLabel {
    .tags {
        margin-bottom: 1px;
    }
    .tagContainer {
        padding: 0 16px;
    }
    .noPersonTip {
        padding: 0 16px;
    }
    .footer {
        width: 90%;
        left: 50%;
        margin: 25px auto;
        text-align: center;
        .addLabel {
            margin-right: 28px;
        }
    }
}
</style>
